<template>
  <component :is="dashboard[this.currentBg]" />
</template>
<script>
export default {
  data() {
    return {
      currentBg: 'light',
      dashboard: {
        dark: () => import('./dark'),
        light: () => import('./light')
      }
    }
  },
  created() {
    this.currentBg = this.$route.query.bg
  }
}
</script>
